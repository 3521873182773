import React, { useEffect, useState } from "react";
import fp8Logo from '../components/assets/fp8.jpg';

function Navbar() {
  const [sticky, setSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for Consulting dropdown
  const [consultingTitles, setConsultingTitles] = useState([]);

  useEffect(() => {
    const handleScroll = () => setSticky(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Fetch consulting titles for dropdown
  useEffect(() => {
    fetch('/assets/consulting.json')
      .then(response => response.json())
      .then(data => setConsultingTitles(data.map(item => item.title)))
      .catch(error => console.error("Error fetching consulting titles:", error));
  }, []);

  const navItems = [
    { name: "AI Marketplace", href: "/" },
    { name: "HPC Brokering", href: "/hpc-brokering" },
    { name: "Consulting", href: "/consulting" },
    { name: "Research", href: "/research" },
    { name: "Models", href: "/models" },
  ];

  return (
    <div
      className={`max-w-screen-2xl container mx-auto md:px-20 px-4 fixed top-0 left-0 right-0 z-50 ${
        sticky ? "shadow-md bg-[#2a0530] text-white" : "bg-transparent"
      } transition-all duration-300 ease-in-out`}
    >
      <div className="flex items-center justify-between py-4 text-white">
        <div className="navbar-start flex">
          <a className="text-2xl font-bold cursor-pointer px-2">FP8</a>
          <img src={fp8Logo} alt="Adversarial AI" className="w-10 h-10 shadow-lg rounded-lg" />
        </div>
        <div className="navbar-end lg:flex lg:space-x-6 lg:ml-auto hidden">
          {navItems.map((item) =>
            item.name === "Consulting" ? (
              <div key={item.name} className="relative">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown on click
                  className="block p-2 rounded hover:bg-gray-700 transition duration-200 flex items-center"
                >
                  {item.name}
                  <span className="ml-2">{dropdownOpen ? "▲" : "▼"}</span>
                </button>
                {/* Dropdown menu, visible on click */}
                {dropdownOpen && (
                  <div className="absolute bg-[#2a0530] text-white mt-2 w-64 shadow-lg rounded-lg">
                    {consultingTitles.map((title, index) => (
                      <a
                        key={index}
                        href={`/consulting#section-${index}`}
                        className="block px-4 py-2 hover:bg-gray-700"
                      >
                        {title}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <a
                key={item.name}
                href={item.href}
                className="block p-2 rounded hover:bg-gray-700 transition duration-200"
              >
                {item.name}
              </a>
            )
          )}
        </div>
        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-4 6h-8"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Dropdown Menu for Mobile */}
      {menuOpen && (
        <div className="lg:hidden flex flex-col space-y-2 mt-2">
          {navItems.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="block p-2 rounded bg-gray-400 hover:bg-gray-700 transition duration-200"
            >
              {item.name}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export default Navbar;
