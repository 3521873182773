import './App.css';
import Home from './components/home/Home';
import ModelsPage from './components/models/ModelsPage';
import ResearchPage from './components/research/ResearchPage';
import ConsultingPage from './components/consulting/ConsultingPage';
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router >
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/models" element={<ModelsPage />} />
      <Route path="/research" element={<ResearchPage />} />
      <Route path="/consulting" element={<ConsultingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
