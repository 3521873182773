import React from 'react';
import Navbar from '../Navbar';
import Consulting from '../consulting/Consulting.js';

function ConsultingPage() {
  return (
    <>
        <Navbar />
        <Consulting />
    </>
  );
}

export default ConsultingPage;
