import React from 'react'
import Research from "../research/Research.js";
import Navbar from '../Navbar';

function ResearchPage() {
  return (
    <>
      <Navbar />
      <Research />
    </>
  )
}

export default ResearchPage
