import React, { useEffect, useState, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Modal from "../modal";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";

const pdfjsDistVersion = "3.11.174"; // Ensure this matches the installed version

function ResearchItem({ research, onClick }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);

  return (
    <motion.div
      ref={ref}
      style={{
        scale: scaleProgress,
        opacity: opacityProgress,
        transition: "transform 0.5s ease, opacity 0.5s ease",
      }}
      className="bg-gray-100 w-full max-w-[500px] border border-black/5 rounded-lg overflow-hidden p-6 hover:bg-gray-200 transition dark:text-white dark:bg-white/10 dark:hover:bg-white/20 cursor-pointer relative"
    >
      <div className="flex flex-col h-full">
        <h3 className="text-2xl font-semibold mb-4 text-center">{research.title}</h3>
        <p className="leading-relaxed text-gray-700 dark:text-white/70 mb-4 text-center">
          {research.description}
        </p>
        <p className="leading-relaxed text-gray-700 dark:text-white/70 mb-4 text-lg font-semibold">
          Components:
        </p>
        <ul className="flex flex-wrap justify-center gap-2 mb-4">
          {research.tags.map((tag, index) => (
            <li
              className="bg-black/[0.7] px-3 py-1 text-[0.7rem] uppercase tracking-wider text-white rounded-full dark:text-white/70"
              key={index}
            >
              {tag}
            </li>
          ))}
        </ul>

        <button
          onClick={() => onClick(research)}
          className="bg-blue-500 text-white font-semibold py-2 px-4 rounded mt-4 hover:bg-blue-600 focus:ring focus:ring-blue-300 focus:outline-none"
        >
          Read More
        </button>

        <p className="text-gray-500 dark:text-gray-400 text-sm pt-5 flex items-center justify-end">
          Post date: <span className="pl-2">{new Date(research.date).toLocaleDateString()}</span>
        </p>
      </div>
    </motion.div>
  );
}

export default function Research() {
  const [researchItems, setResearchItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState(null); // No sort by default
  const [selectedResearch, setSelectedResearch] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetch("/assets/Research.json")
      .then((response) => response.json())
      .then((data) => {
        setResearchItems(data);
        setFilteredItems(data); // Set initial filtered items in original order
      })
      .catch((error) => console.error("Error loading research data:", error));
  }, []);

  // Apply search and sorting filters
  useEffect(() => {
    let items = researchItems.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Apply sort based on sortOrder if it's set
    if (sortOrder) {
      items = items.slice().sort((a, b) => {
        return sortOrder === "asc"
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      });
    }

    setFilteredItems(items);
  }, [searchQuery, sortOrder, researchItems]);

  const openModal = (research) => {
    setSelectedResearch(research);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedResearch(null);
  };

  const clearSearch = () => setSearchQuery("");

  return (
    <div className="flex flex-col items-center py-20 bg-gradient-to-br from-[#2a0530] via-black to-[#2a0530] text-white">
      <div className="text-center mb-8">
        <h1 className="text-5xl font-bold">Research</h1>
        <p className="text-lg mt-2">Market insights from the FP8 team.</p>
      </div>

      <div className="bg-[#101030] p-6 rounded-lg w-full max-w-xl mb-10">
        <div className="flex justify-between items-center">
          <label className="block text-lg mb-2">Search</label>
          <button onClick={clearSearch} className="text-blue-500">
            Clear
          </button>
        </div>
        <input
          type="text"
          placeholder="Search all articles"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-3 rounded-lg bg-gray-200 text-black mb-4"
        />
        <div className="mt-4 flex items-center justify-between">
          <button
            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            className="flex items-center text-blue-500 font-semibold"
          >
            {sortOrder === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />}
            <span className="ml-2">Sort by Date</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-5">
        {filteredItems.map((research, index) => (
          <ResearchItem key={index} research={research} onClick={openModal} />
        ))}
      </div>

      {isModalOpen && selectedResearch && (
        <Modal.ModalContainerforpdf onClose={closeModal}>
          <div className="py-5">
            <a
              href={selectedResearch.pdfLink}
              download={`${selectedResearch.title}.pdf`}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 focus:ring focus:ring-blue-300 focus:outline-none"
            >
              Download PDF
            </a>
          </div>
          <div className="w-full flex justify-center px-4 pb-10">
            <div className="w-full max-w-4xl">
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsDistVersion}/build/pdf.worker.min.js`}>
                <Viewer fileUrl={selectedResearch.pdfLink} />
              </Worker>
            </div>
          </div>
        </Modal.ModalContainerforpdf>
      )}
    </div>
  );
}
