import React from 'react'

function ModelsPage() {
  return (
    <div>
      <p>Models</p>
    </div>
  )
}

export default ModelsPage
