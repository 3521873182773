import React from 'react';
import visionImage from '../../components/assets/visionImage.jpg';

function OurVision() {
  return (
    <div className="flex items-center justify-center pt-20 pb-20 text-gray-300 bg-gradient-to-tr from-black via-black to-[#2a0530]">      
      <div className="flex flex-col md:flex-row">

        {/* Text Section */}
        <div className="md:w-3/5 lg:ml-20 flex flex-col justify-center px-9">
          <h1 className=" text-8xl  text-transparent bg-clip-text bg-gradient-to-r to-purple-950 from-purple-300"
           >Our Vision</h1>
          <p className="text-2xl pt-10">
            At FP8, we’re transforming compute power into a tradable commodity, making AI infrastructure as accessible as any other resource.
          </p>
          <p className="text-2xl pt-4">
            Compute, like any other commodity, deserves a transparent, two-way market that determines its fair market value.
          </p>
          <p className="text-2xl pt-4">
            By unlocking innovative ways to trade, scale, and utilize compute resources, FP8 empowers global innovation and opens up new possibilities for AI-driven solutions worldwide.
          </p>
        </div>

        {/* Image Section */}
        <div className="flex justify-center items-center md:w-2/5">
          <img 
            src={visionImage} 
            alt="Vision" 
            className="w-64 h-64 rounded-full border-4 border-gray-300 shadow-lg" 
          />
        </div>
      </div>
    </div>
  );
}



export default OurVision;