import React from 'react';
import Modal from '../modal';
import circleicon from '../../components/assets/circle_icon.png';
import bulletImage from '../../components/assets/bullet_icon.png';
import AdverserialAI  from '../../components/assets/AdverserialAI.png';
import DataCenterConsultingicon from '../../components/assets/DataCenterConsultingicon.png';
import SyntheticDataGeneration from '../../components/assets/SyntheticDataGeneration.png';
import RAGOptimization from '../../components/assets/RAGOptimization.png';
import LLMAgentCreationServices from '../../components/assets/LLMAgentCreationServices.png';
import DatabaseOptimization from '../../components/assets/DatabaseOptimization.png';
import GPUSoftwareConsulting from '../../components/assets/GPUSoftwareConsulting.png';
import ColocationBrokeageServices from '../../components/assets/ColocationBrokeageServices.png';
import EquipmentFinancing from '../../components/assets/EquipmentFinancing.png';
import GPUSoftwareSolutions from '../../components/assets/GPUSoftwareSolutions.png';
import HardwareProcurementServices from '../../components/assets/HardwareProcurementServices.png';

function BuyerSellerServices() {
  return (
    <div className="px-10 py-10 bg-black text-white">
      {/* Header section with title and image */}
      <div className="flex items-center justify-center mb-8">
        <h1 className="text-8xl text-transparent bg-clip-text text-purple-300 text-center">
          My Services
        </h1>
      </div>

      {/* Circle Icon */}
      <div className="flex items-center justify-center sm:mb-8 mb-0">
        <img
          src={circleicon}
          alt="Vision"
          className="w-10 h-10 rounded-full border-4 border-gray-300 shadow-lg"
        />
      </div>

      {/* Horizontal line and vertical line styling */}
      <div className="relative flex items-center justify-center sm:mb-8 mb-0">
        {/* Left Vertical Line */}
        <div className="border-l border-white h-16 top-1/2 transform -translate-y-1/6 lg:-translate-y-1/2"></div>

        {/* Horizontal Line */}
        <div className="absolute w-1/2 border-t border-white top-1/2 transform -translate-y-1/2 sm:block hidden"></div>

        {/* Right Vertical Lines */}
        <div className="absolute right-1/4 border-l border-white h-16 top-1/2 transform -translate-y-1/6 sm:block hidden"></div>
        <div className="absolute left-1/4 border-l border-white h-16 top-1/2 transform -translate-y-1/6 sm:block hidden"></div>
      </div>

      {/* Service Boxes */}
      <div className="flex flex-col md:flex-row gap-8 py-10">
        {/* Box 1: Buyers Services */}
        <div className="flex-1 flex items-center justify-center">
          <Modal.Container>
            <div className="w-[300px] h-[400px] rounded-lg p-6 shadow-lg flex flex-col justify-start items-center">
              <h2 className="text-2xl font-semibold mb-4">Buyers Services:</h2>
              <div className="space-y-3">
                <div>
                  <div className="flex items-center">
                    <img src={AdverserialAI} alt="Adversarial AI" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">Adversarial AI Solutions.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={SyntheticDataGeneration} alt="Data Center" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">Synthetic Data Services.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={RAGOptimization} alt="Synthetic Data" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">RAG Optimization.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={LLMAgentCreationServices} alt="RAG Optimization" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">LLM Agent Creation Services.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={DatabaseOptimization} alt="LLM Agent" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">Database Optimization.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={GPUSoftwareConsulting} alt="LLM Agent" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">GPU Software Consulting.</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Container>
        </div>

        {/* Box 2: Seller Services */}
        <div className="flex-1 flex items-center justify-center">
          <Modal.Container>
            <div className="w-[300px] h-[400px] rounded-lg p-6 shadow-lg flex flex-col justify-start items-center">
              <h2 className="text-2xl font-semibold mb-4">Seller Services:</h2>
              <div className="space-y-3">
                <div>
                  <div className="flex items-center">
                    <img src={ColocationBrokeageServices} alt="Database Optimization" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">Colocation Brokerage Services.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={EquipmentFinancing} alt="Database Optimization" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">Equipment Financing.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={DataCenterConsultingicon} alt="Database Optimization" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">Data Center Consulting.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={GPUSoftwareSolutions} alt="Database Optimization" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">GPU Software Solutions.</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <img src={HardwareProcurementServices} alt="Database Optimization" className="w-10 h-10 shadow-lg" />
                    <p className="text-lg pl-2 font-semibold">Hardware Procurement Services.</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Container>
        </div>
      </div>
    </div>
  );
}

  

export default BuyerSellerServices;