import React from 'react'
import Navbar from '../Navbar';
import Header from '../home/Header';
import OurVision from '../home/OurVision';
import BuyerSellerServices from '../home/BuyerSellerServices';
function Home() {
  return (
    <>
    <Navbar />
    <Header />
    <OurVision />
    <BuyerSellerServices />
    </>
  )
}

export default Home
